import launguage from "@/launguage";

const authRoute = {
  path: "/:lang/auth/",
  component: () => import(/* webpackChunkName: "home" */ "@/views/layout/Layout.vue"),
  beforeEnter: launguage,
  children: [
    {
      path: "login",
      name: "Login",
      component: () => import(/* webpackChunkName: "auth" */ "@/views/Authscreen/login.vue")
    },
    {
      path: "changepassword",
      name: "changepassword",
      component: () =>
        import(/* webpackChunkName: "auth" */ "@/views/Authscreen/changepassword.vue")
    },
    {
      path: "resetpassword/:uid/:token",
      name: "resetpassword",
      component: () =>
        import(/* webpackChunkName: "auth" */ "@/views/Authscreen/resetpassword.vue")
    },
    {
      path: "changeusername",
      name: "changeusername",
      component: () =>
        import(/* webpackChunkName: "auth" */ "@/views/Authscreen/changeusername.vue")
    },
    {
      path: "changesuccess",
      name: "changesuccess",
      component: () =>
        import(/* webpackChunkName: "auth" */ "@/views/Authscreen/changesuccess.vue")
    },
    {
      path: "role",
      name: "Role",
      component: () => import(/* webpackChunkName: "auth" */ "@/views/Authscreen/role.vue")
    },
    {
      path: "fblogin",
      name: "FacebookLogin",
      component: () =>
        import(/* webpackChunkName: "auth" */ "@/views/Authscreen/FacebookLogin.vue")
    }
  ]
};

export default authRoute;

import launguage from "@/launguage";
import Vue from "vue";
import Router from "vue-router";

const general = {
  path: "/:lang",
  component: () => import(/* webpackChunkName: "home" */ "@/views/layout/Layout.vue"),
  beforeEnter: launguage,
  // meta: {
  //     title: 'qwert',
  //     metaTags: [
  //       {
  //         name: 'image',
  //         content: 'The about page of our example app.'
  //       },
  //       {
  //         property: 'og:image',
  //         content: 'http://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg'
  //       }
  //     ]
  //   },
  children: [
    {
      path: "",
      name: "Home",
      component: () => import(/* webpackChunkName: "home" */ "@/views/Homescreen.vue")
    },
    {
      path: "privacy",
      name: "Privacy",
      component: () => import(/* webpackChunkName: "general" */ "@/views/PrivacyPolicy.vue")
    },
    {
      path: "privacypolicy",
      name: "AndroidPrivacyPolicy",
      component: () => import(/* webpackChunkName: "general" */ "@/views/AndroidPrivacyPolicy.vue")
    },
    {
      path: "register",
      name: "Register",
      component: () => import(/* webpackChunkName: "general" */ "@/views/Authscreen/RegisterUser.vue")
    },
    {
      path: "chooserole",
      name: "Chooserole",
      component: () => import(/* webpackChunkName: "general" */ "@/views/Authscreen/Companyreg/Chooserole.vue")
    },
    {
      path: "registersteps",
      name: "Registersteps",
      component: () => import(/* webpackChunkName: "general" */ "@/views/Authscreen/Companyreg/Registrationsteps.vue")
    },
    {
      path: "forgotpassword",
      name: "forgotpassword",
      component: () =>
        import(/* webpackChunkName: "general" */  "@/views/Authscreen/forgotpassword.vue")
    },
    {
      path: "profile",
      name: "Profile",
      component: () => import(/* webpackChunkName: "general" */ "@/views/Authscreen/profile.vue")
    },
    {
      path: "editprofile",
      name: "EditProfile",
      component: () => import(/* webpackChunkName: "general" */ "@/views/Authscreen/EditProfile.vue")
    },
    {
      path: "activate/:uid/:token",
      name: "Activate",
      component: () => import(/* webpackChunkName: "general" */ "@/views/Authscreen/activate.vue")
    },
    {
      path: "varieties",
      name: "Variety",
      component: () => import(/* webpackChunkName: "general" */ "@/views/SeedCatalog/CropVarieties.vue")
    },
    {
      path: "varietydetail",
      name: "VarietyDetail",
      component: () => import(/* webpackChunkName: "general" */ "@/views/SeedCatalog/CropVarietyDetail.vue")
    },
    // {
    //   path: "scm",
    //   name: "scm",
    //   component: () => import("@/views/Admin/SCM.vue")
    // },
    {
      path: "catalog/:cropSlug/",
      name: "ListingScreen",
      component: () => import(/* webpackChunkName: "general" */ "@/views/ListingScreen.vue")
    },
    {
      path: "catalog/:cropSlug/:varietySlug/",
      name: "DetailScreen",
      component: () => import(/* webpackChunkName: "general" */ "@/views/DetailScreen.vue")
      //   meta: {
      //   title: 'qwert',
      //   metaTags: [
      //     {
      //       name: 'image',
      //       content: 'The about page of our example app.'
      //     },
      //     {
      //       property: 'og:image',
      //       content: "http://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg"
      //     }
      //   ]
      // },
    },
    {
      path: "varietylist",
      name: "VarietyList",
      component: () => import(/* webpackChunkName: "general" */ "@/views/Varietylist.vue")
    },
    {
      path: "comparescreen",
      name: "comparescreen",
      component: () => import(/* webpackChunkName: "general" */ "@/views/CompareCrops.vue")
    },
    {
      path: "supplier",
      name: "SupplierSearch",
      component: () => import(/* webpackChunkName: "general" */ "@/views/SupplierSearch/AdvancedSupplierSearch.vue")
    },
    {
      path: "supplier/:userSlug/",
      name: "SupplierDetail",
      component: () => import(/* webpackChunkName: "general" */ "@/views/SupplierSearch/SupplierDetail.vue")
    }
  ]
};

export default general;

import JWTStore from "@/store/modules/jwt";
import launguage from "@/launguage";

const companyRoute = {
  path: "/:lang/company",
  component: () => import(/* webpackChunkName: "home" */ "@/views/layout/Layout.vue"),
  beforeEnter: launguage,
  children: [
    {
      path: "menu",
      name: "CompanyMenu",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            (localStorage.getItem("role") === "consumer" ||
              localStorage.getItem("role") === "supplier")
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "company" */ "@/views/Company/CompanyUserMenu.vue")
    },
    {
      path: "addsupply",
      name: "AddSupply",
      meta: {
        // needsAuth must be false
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            (localStorage.getItem("role") === "consumer" ||
              localStorage.getItem("role") === "supplier")
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "company" */ "@/views/Company/AddSupply.vue")
    },
    {
      path: "adddemand",
      name: "AddDemand",
      meta: {
        // needsAuth must be false
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            (localStorage.getItem("role") === "consumer" ||
              localStorage.getItem("role") === "supplier")
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "company" */ "@/views/Company/AddDemand.vue")
    },
    {
      path: "supplylist",
      name: "SupplyList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            (localStorage.getItem("role") === "consumer" ||
              localStorage.getItem("role") === "supplier")
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "company" */ "@/views/Company/SupplyList.vue")
    },
    {
      path: "demandlist",
      name: "DemandList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            (localStorage.getItem("role") === "consumer" ||
              localStorage.getItem("role") === "supplier")
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "company" */ "@/views/Company/DemandList.vue")
    },
    {
      path: "supplylist/:clusterId/",
      name: "SupplyDetail",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            (localStorage.getItem("role") === "consumer" ||
              localStorage.getItem("role") === "supplier")
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "company" */ "@/views/Company/SupplyDetail.vue")
    },
    {
      path: "demandlist/:clusterId/",
      name: "DemandDetail",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            (localStorage.getItem("role") === "consumer" ||
              localStorage.getItem("role") === "supplier")
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "company" */ "@/views/Company/DemandDetail.vue")
    },
    {
      path: "allocation",
      name: "AllocationList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            (localStorage.getItem("role") === "consumer" ||
              localStorage.getItem("role") === "supplier")
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "company" */ "@/views/Company/AllocationList.vue")
    },
    {
      path: "preferences",
      name: "Preferences",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            (localStorage.getItem("role") === "consumer" ||
              localStorage.getItem("role") === "supplier")
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "company" */ "@/views/Company/Preferences.vue")
    },
    {
      path: "distribution",
      name: "DistributionList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            (localStorage.getItem("role") === "consumer" ||
              localStorage.getItem("role") === "supplier")
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "company" */ "@/views/Company/DistributionList.vue")
    },
    {
      path: "distribution/:supplyId/",
      name: "DistributionDetail",
      meta: {
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            (localStorage.getItem("role") === "consumer" ||
              localStorage.getItem("role") === "supplier")
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "company" */ "@/views/Company/DistributionDetail.vue")
    },
    {
      path: "report",
      name: "AllocationReport",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && localStorage.getItem("role") === "supplier") {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/Reports/ReportMenu.vue")
    }
  ]
};

export default companyRoute;

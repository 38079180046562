import JWTStore from "@/store/modules/jwt";
import launguage from "@/launguage";

const adminRoute = {
  path: "/:lang/admin",
  component: () => import(/* webpackChunkName: "home" */ "@/views/layout/Layout.vue"),
  beforeEnter: launguage,
  children: [
    {
      path: "/",
      name: "Admin",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Adminmain.vue")
    },
    {
      path: "Adminmenu",
      name: "Adminmenu",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Adminmenu.vue")
    },
    {
      path: "chooseallocation",
      name: "ChooseAllocation",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/ChooseAllocation.vue")
    },
    {
      path: "allocation",
      name: "Allocation",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Allocation.vue")
    },
    {
      path: "allocationlist/forwarded_to_chief",
      name: "AllocationForwardedToChief",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && localStorage.getItem("role") === "SQCCChief") {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/AllocationForwardedToChief.vue")
    },
    {
      path: "allocationlist",
      name: "AdminAllocationList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/AllocationList.vue")
    },
    {
      path: "allocationlist/saved",
      name: "SavedAllocationList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/SavedAllocationList.vue")
    },
    {
      path: "allocationdetail",
      name: "AdminAllocationDetail",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/AllocationDetail.vue")
    },
    {
      path: "crop",
      name: "AddCrop",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Crop.vue")
    },
    {
      path: "cropvarietyvalue",
      name: "CropVarietyValue",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/CropVarietyValue.vue")
    },
    {
      path: "cropaddnewvariety",
      name: "CropAddNewVariety",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/CropAddNewVariety.vue")
    },
    {
      path: "crop",
      name: "Crop",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          console.log("first");
          console.log(JWTStore.loggedIn);
          console.log(localStorage.getItem("role"));
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            console.log("second");
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Crop.vue")
    },
    {
      path: "cropvarietyvaluelist",
      name: "CropVarietyValueList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/CropVarietyValueList.vue")
    },
    {
      path: "userlist",
      name: "UserList",
      meta: {
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/UserSettings.vue")
    },
    {
      path: "rolelist",
      name: "RoleList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/UserInRoleList.vue")
    },
    {
      path: "demandsupplycalendar",
      name: "DemandSupplyCalendar",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/DemandSupplySchedule.vue")
    },
    {
      path: "adddemandsupplygroup",
      name: "AddDemandSupplyGroup",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/AddDemandSupplyGroup.vue")
    },
    {
      path: "RegisterseedComp",
      name: "Seedcomregister",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Seedcomregister.vue")
    },
    {
      path: "supplylist",
      name: "AdminSupplyList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/SupplyList.vue")
    },
    {
      path: "demandlist",
      name: "AdminDemandList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/DemandList.vue")
    },
    {
      path: "report",
      name: "ReportMenu",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/Reports/ReportMenu.vue")
    },
    {
      path: "balancesheet",
      name: "BalanceSheet",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/Reports/BalanceSheet.vue")
    },
    {
      path: "UnallocatedReport",
      name: "UnallocatedReport",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && localStorage.getItem("role") === "SQCCAdmin") {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/Reports/UnallocatedReport.vue")
    },
    {
      path: "adhocreport",
      name: "AdHocReport",
      meta: {
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "adhoc" */ "@/views/Admin/Reports/AdHocReport.vue")
    },
    {
      path: "categoryreport",
      name: "CategoryReport",
      meta: {
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/Reports/CategoryReport.vue")
    },
    {
      path: "allocationreport",
      name: "AllocationReport",
      meta: {
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/Reports/AllocationReportList.vue")
    },
    {
      path: "demandforecastreport",
      name: "DemandForecastReport",
      meta: {
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/Reports/DemandForecastReport.vue")
    },
    {
      path: "unsoldAllocationReport",
      name: "unsoldAllocationReport",
      meta: {
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/Reports/UnsoldAllocationReport.vue")
    },
    {
      path: "unsoldAllocationReportDetail",
      name: "unsoldAllocationReportDetail",
      meta: {
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(
          /* webpackChunkName: "admin" */ "@/views/Admin/Reports/UnsoldAllocationReportDetail.vue"
        )
    },
    {
      path: "sendMailLogs",
      name: "SendMailLogs",
      meta: {
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/SendMailLogs.vue")
    },
    {
      path: "dashboard",
      name: "Dashboard",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Dashboard.vue")
    },
    {
      path: "commentlist",
      name: "AdminCommentList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/CommentList.vue")
    },
    {
      path: "SupplyReport",
      name: "SupplyReport",
      meta: {
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/Reports/SupplyReport.vue")
    },
    {
      path: "DemandReport",
      name: "DemandReport",
      meta: {
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            ["SQCCAdmin", "SQCCChief"].includes(String(localStorage.getItem("role")))
          ) {
            next();
          } else {
            next({
              path: "/en"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/Reports/DemandReport.vue")
    }
  ]
};

export default adminRoute;
